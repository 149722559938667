
.santa-background {
    background: url(../img/snowflakes.jpg) fixed !important;

    .site {
        background-color: #dbeeff;
    }

    .footer-bg{
     background: #c6e4ff;
    }

    .top{
     background: #c6e4ff;
    }
}



.pumpkin-background {
    background: url(../img/pumpkin-fest/back.jpg) fixed !important;

    .site {
        // background-color: #dbeeff;
        background: rgba(242, 229, 210, 0);
        box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0);
    }

    .footer-bg{
    //  background: #333;
      background: rgba(240, 248, 255, 0) !important;
      color: #fff;
    }

    .top{
    //  background: #333;
        background: rgba(240, 248, 255, 0) !important;
    }
}


@import url('https://fonts.googleapis.com/css?family=Courgette');
  #pumpkin-slider .item{
    margin: 5px;
  }
  #pumpkin-slider .item img{
    display: block;
    width: 100%;
    height: auto;
  }

  #christmas-slider .item{
    margin: 1px;
  }
  #christmas-slider .item img{
    display: block;
    width: 100%;
    height: auto;
  }

.special-offer {
	width: 90%;
	margin: 40px 0px 25px 0px;
	padding: 0px 10px;
	box-shadow: 8px 8px 3px #888888;
	background-image: url("../img/offer-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	border: 2px dashed  #000;
	border-radius: 10px;


}

.bgoffer2 {
	background-image: url("../img/offer2-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
}

.bgoffer3 {
	background-image: url("../img/offer3-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
}

#owl-demo .item{
  margin: 0px;
}
#owl-demo .item img{
  display: block;
  width: 100%;
  height: auto;
}

#owl-1 .item{
  margin: 0px;
}
#owl-demo1 .item img{
  display: block;
  width: auto;
  height: auto;
}

#owl-2 .item{
  margin: 0px;
}
#owl-demo2 .item img{
  display: block;
  width: 100%;
  height: auto;
}

#owl-3 .item{
  margin: 0px;
}
#owl-demo3 .item img{
  display: block;
  width: 100%;
  height: auto;
}

#owl-demo4 .item{
  margin: 0px;
}
#owl-demo4 .item img{
  display: block;
  width: 100%;
  height: auto;
}

#owl-demo5 .item{
  margin: 0px;
}
#owl-demo5 .item img{
  display: block;
  width: 100%;
  height: auto;
}


.mobile-nav {
	color: @body-bg;
}


.logo-img img {
	position: relative;
	bottom: 0;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.logo-img img:hover {
	bottom: 5px;
}

.index-contact p, .index-contact label {
	color: @header-contrast;
}

.top-tiles div {
	margin: 0;
	padding: 0;
}

.top-tiles .tile {
	overflow: hidden;
	position: relative;
	margin: 5px;
}

.top-tiles img {
	width: 100%;

	filter: blur(0px);
	-webkit-filter: blur(0px);
	-moz-filter: blur(0px);
	-ms-filter: blur(0px);
	-o-filter: blur(0px);


	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

.top-tiles .tile:hover img {
	filter: blur(5px);
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-ms-filter: blur(5px);
	-o-filter: blur(5px);
}

.top-tiles .tile:hover p {
	opacity: 1;
}

@media (min-width: 800px) and (max-width: 1000px) {
    .top-tiles h2 {
        font-size: 150%;
    }
    .top-tiles .tile:hover img {
        filter: blur(0);
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -ms-filter: blur(0);
        -o-filter: blur(0);
        opacity: 0.7;
    }
}

@media screen and (max-width: 800px) {
    .top-tiles h2 {
        font-size: 120%;
    }
    .top-tiles .tile:hover img {
        filter: blur(0);
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -ms-filter: blur(0);
        -o-filter: blur(0);
        opacity: 0.7;
    }
}

.top-tiles .tile:hover h2 {
	color: @headers-etc;
}

.top-tiles h2 {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	margin: 0;
	color: white;
	padding: 10px;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

.top-tiles p {
	position: absolute;
	background-color: rgba(0,0,0,0.5);
	color: #fff;
	padding: 10px;
	top: 0;
	opacity: 0;
	margin: 20px;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

a {
	transition: all 0.25s;
	-webkit-transition: all 0.25s;
	-ms-transition: all 0.25s;
	-moz-transition: all 0.25s;
	-o-transition: all 0.25s;
}

.logo-contact {
	text-align: right;
	color: @header-contrast;
	font-size: 35px;
	font-weight: 700;
}

.logo-contact a {
	color: @header-contrast;
	text-decoration: none;
}

.logo-contact a:hover {
	color: @headers-etc;
}

@media screen and (max-width: 991px) {
    .logo-contact {
		text-align: center;
	}
}

.social-icon {
	position: relative;
	bottom: 0;

	transition: all 0.1s;
	-webkit-transition: all 0.1s;
	-ms-transition: all 0.1s;
	-moz-transition: all 0.1s;
	-o-transition: all 0.1s;
}

.social-icon:hover {
	bottom: 5px;
	box-shadow: 0 5px 0 @headers-etc;
}

.index-contact .submit {
	color: contrast(@brand-primary) !important;
	float: right;
}

.index-contact {
	background-color: @brand-primary;
	padding: 20px;
	border-radius: 5px;
	margin-top: 15px;
}

.site {
	background: @body-bg; //url("../img/plumbing-pipes.jpg");
    box-shadow: 0 0 20px -3px black;
}

.top {
	position: relative;
	width:100%;
	background: url(../img/header.png) fixed repeat;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

.logo {
	position: relative;
}
.logo-img {
	z-index: 99;
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @body-bg;
}
.main-content {
	margin-top: 15px;
}
h1 {
	margin-bottom: 30px;
	}
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;

	h2, p, a {
		color: @headings-alt;
	}

	h3 {
	     color:#E0C389;
	}

}
.bottom-content {
	margin-bottom: 40px;
}

.bullet-bg {
	background-color: @brand-primary;
	border-radius: 10px;
	padding:10px;

	li {
		margin-left:30px;
		color: #fff;
	}
	}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background: url(../img/header.png) fixed repeat;
	padding: 20px 0;
	margin-top: 40px;
	border-top: solid 5px @headers-etc;
	color: @footer-text;
}
.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
		-webkit-filter: grayscale(100%);
		.transition(.5s);
	}
}
.acceditation {
	img:hover {
		-webkit-filter: grayscale(0%);
		.transition(.5s);
	}
}
.contact {
	text-align: center;
}

@media screen and (min-width: @screen-sm-min) {
	.contact {
		text-align: right;
	}
}

#area-map,
#area-map-small {
	margin-top: 30px;
}
.subtitle-pge {
	border-bottom: 1px solid #CBCBCB;
	border-top: 1px solid #CBCBCB;
	line-height: 1.4;
	margin-bottom: 25px;
	padding-bottom: 25px;
	margin-top: 25px;
	padding-top: 25px;
	font-weight: 200;
	text-align: center;
}



.get {
    display:none;
}
.legend {
// float:right;
width:250px;
margin-top:140px;
}
.skills {
float:left;
clear:both;
width:100%;
}

.skills ul,
.skills li {
display:block;
list-style:none;
margin:0;
padding:0;
}

.skills li {
float:right;
clear:both;
padding:0 15px;
height:35px;
line-height:35px;
color:#fff;
margin-bottom:1px;
font-size:18px;
}

.skills .jq {
background:#97BE0D;
}

.skills .css {
background:#D84F5F;
}

.skills .html {
background:#88B8E6;
}

.skills .php {
background:#BEDBE9;
}

.skills .sql {
background:#EDEBEE;
}





@import url(https://fonts.googleapis.com/css?family=Bangers);

html {
  min-height: 100%;
}


body2 {
  font-family: Bangers;
  font-size:100px;
 // background: -webkit-radial-gradient(50% 100%, yellow, red);
 // background: radial-gradient(50% 100%, yellow, red);
  color: #8B0E3A;
}

.welcome-text{

  width:650px;
  margin:1% auto 0;

  ul{
    padding:0;
    margin:0;

    li{
      float:left;
      list-style:none;
      padding:10px;
      color:;
      text-shadow: 2px 2px #ff0000;

      &:nth-child(even){

       -webkit-animation-name: wiggleEven;
      -webkit-animation-duration: .6s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
               animation-name: wiggleEven;
   animation-duration: .6s;
     animation-iteration-count: infinite;
      animation-timing-function: ease;

      }

      &:nth-child(odd){
       -webkit-animation-name: wiggleOdd;
      -webkit-animation-duration: .4s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      animation-name: wiggleOdd;
      animation-duration: .4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      }
    }



  }
}


@-webkit-keyframes wiggleEven {
    from {-webkit-transform: rotateZ(10deg) translateY(4px);}
    50% {-webkit-transform: rotateZ(-20deg) translateY(-4px);}
    to {-webkit-transform: rotateZ(10deg) translateY(4px);}
}

@-webkit-keyframes wiggleOdd {
  from {-webkit-transform: rotateZ(-20deg) translateY(-4px)}
    50% {-webkit-transform: rotateZ(2deg) translateY(4px);}
    to {-webkit-transform: rotateZ(-20deg) translateY(-4px);}
}

@keyframes wiggleEven {
    from {transform: rotateZ(10deg) translateY(4px);}
    50% {transform: rotateZ(-20deg) translateY(-4px);}
    to {transform: rotateZ(10deg) translateY(4px);}
}

@keyframes wiggleOdd {
  from {transform: rotateZ(-20deg) translateY(-4px)}
    50% {transform: rotateZ(2deg) translateY(4px);}
    to {transform: rotateZ(-20deg) translateY(-4px);}
}
body {
    // background: #F2E5D2;
}
.gift {
    position: absolute;
    bottom: 0px;
    width: 150px;
    left: 172px;
    z-index: 12;
    height: 180px;
}
.wrap {
    height: 300px;
    width: 800px;
    margin: 0px auto;
    position: relative;
}
.ribbon_right {
    width: 45px;
    height: 45px;
    border-radius: 100% 100% 100% 0px;
    box-shadow: 0px 0px 0px 10px #1495f1 inset;
    position: absolute;
    right: 30px;
}
.ribbon_left {
    width: 45px;
    height: 45px;
    border-radius: 100% 100% 0px 100%;
    box-shadow: 0px 0px 0px 10px #1aa8fc inset;
    position: absolute;
    left: 30px;
}
.gift_box_top {
    height: 28px;
    top: 45px;
    position: absolute;
    width: 150px;
    background: #ff7d6d;
    box-shadow: -75px 0px 0px #ff6259 inset;
}
.gift_ribbon_center {
    width: 2px;
    bottom: 0px;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0px auto;
    background: #1cadfe;
    height: 140px;
    border-right: 6px solid #28C9FF;
    border-left: 6px solid #28C9FF;
    z-index: 10;
}
.gift_box_bottom {
    background: #ff8168;
    bottom: 0px;
    height: 102px;
    overflow: hidden;
    right: 7px;
    position: absolute;
    width: 136px;
    box-shadow: -70px 0px 0px #fe6d68 inset;
}
.gift_box_bottom_top {
    height: 5px;
    width: 136px;
    right: 7px;
    position: absolute;
    bottom: 102px;
    background: #dd4b4c;
}
.gift_ribbon_left {
    height: 55px;
    width: 15px;
    left: 15px;
    position: absolute;
    background: #28c9ff;
    top: 40px;
    z-index: 11;
}
.gift_ribbon_left:after {
    border-width: 9px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #ff8168;
    z-index: 12;
    position: absolute;
    content: "";
    bottom: 0px;
}
.gift_box_bottom_ribbon {
    position: absolute;
    right: -25px;
    height: 12px;
    width: 110px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #ff4149;
}
#ribbon1 {
    top: -15px;
}
#ribbon2 {
    top: 20px;
}
#ribbon3 {
    top: 55px;
}
#ribbon4 {
    top: 90px;
}
.line {
    height: 25px;
    width: 100%;
    background: #fff;
    bottom: -25px;
    position: absolute;
}
.cake {
    position: absolute;
    bottom: 0px;
    width: 330px;
    right: 172px;
    height: 240px;
}
.cake_bottom {
    position: absolute;
    width: 330px;
    height: 5px;
    bottom: 0px;
    background: #9dc5c4;
    border-top: 2px solid #e6e7e9;
}
.cake_bottom2 {
    position: absolute;
    width: 320px;
    bottom: 7px;
    background: #cd7a1c;
    height: 22px;
    right: 5px;
    border-radius: 3px 3px 0px 0px;
    left: 5px;
    box-shadow: -160px 0px 0px #ad631c inset;
}
.cake_bottom3 {
    box-shadow: 0px -3px 0px #fddbea, -155px 0px 0px #5a4027 inset;
    height: 52px;
    border-top: 5px solid #ff7ebe;
    background: #744c28;
    position: absolute;
    width: 310px;
    bottom: 29px;
    right: 10px;
    border-radius: 5px 5px 0px 0px;
    left: 10px;
}
.cake_top1 {
    box-shadow: -115px 0px 0px #ff9201 inset;
    height: 46px;
    background: #ffb009;
    position: absolute;
    width: 230px;
    bottom: 89px;
    right: 50px;
    border-radius: 5px 5px 0px 0px;
    left: 50px;
    border-bottom: 3px solid #c14500;
}
.cake_top2 {
    box-shadow: -70px 0px 0px #5b3f29 inset;
    height: 52px;
    background: #744c28;
    position: absolute;
    width: 140px;
    bottom: 138px;
    border-radius: 5px 5px 0px 0px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
}
.cake_line {
    bottom: 0px;
    height: 30px;
    width: 5px;
    position: absolute;
}
#cake_line1 {
    left: 2px;
    background: #ff9101;
}
#cake_line2 {
    left: 12px;
    background: #ff9101;
}
#cake_line3 {
    left: 22px;
    background: #ff9101;
}
#cake_line4 {
    left: 32px;
    background: #ff9101;
}
#cake_line5 {
    left: 42px;
    background: #ff9101;
}
#cake_line6 {
    left: 52px;
    background: #ff9101;
}
#cake_line7 {
    left: 62px;
    background: #ff9101;
}
#cake_line8 {
    left: 72px;
    background: #ff9101;
}
#cake_line9 {
    left: 82px;
    background: #ff9101;
}
#cake_line10 {
    left: 92px;
    background: #ff9101;
}
#cake_line11 {
    left: 102px;
    background: #ff9101;
}
#cake_line12 {
    left: 112px;
    background: #ff9101;
}
#cake_line13 {
    right: 3px;
    background: #ff7000;
}
#cake_line14 {
    right: 13px;
    background: #ff7000;
}
#cake_line15 {
    right: 23px;
    background: #ff7000;
}
#cake_line16 {
    right: 33px;
    background: #ff7000;
}
#cake_line17 {
    right: 43px;
    background: #ff7000;
}
#cake_line18 {
    right: 53px;
    background: #ff7000;
}
#cake_line19 {
    right: 63px;
    background: #ff7000;
}
#cake_line20 {
    right: 73px;
    background: #ff7000;
}
#cake_line21 {
    right: 83px;
    background: #ff7000;
}
#cake_line22 {
    right: 93px;
    background: #ff7000;
}
#cake_line23 {
    right: 103px;
    background: #ff7000;
}
#cake_line24 {
    right: 113px;
    background: #ff7000;
}
.cherry {
    width: 15px;
    top: -15px;
    height: 15px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    box-shadow: -2px -1px 0px 6px #ff4d59 inset;
}
#cherry1 {
    left: 20px;
}
#cherry2 {
    left: 64px;
}
#cherry3 {
    left: 108px;
}
#cherry4 {
    right: 64px;
}
#cherry5 {
    right: 20px;
}
.cake_circle {
    height: 56px;
    width: 56px;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 12px #ffebc8 inset;
    top: -30px;
    z-index: 100;
    position: absolute;
}
.circles {
    position: absolute;
    height: 30px;
    width: 100%;
    overflow: hidden;
    top: 0px;
}
#circle1 {
    left: -1px;
}
#circle2 {
    left: 43px;
}
#circle3 {
    left: 87px;
}
#circle4 {
    right: 43px;
}
#circle5 {
    right: -1px;
}
.cake_top1_creams {
    height: 18px;
    border-radius: 5px;
    background: #f24282;
    position: absolute;
    top: 0px;
    width: 100%;
}
.cake_top1_cream1 {
    position: absolute;
    left: -3px;
    height: 25px;
    width: 15px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream2 {
    position: absolute;
    left: 12px;
    height: 25px;
    width: 10px;
    border-radius: 9px;
    background: #744c28;
    top: 10px;
}
.cake_top1_cream3 {
    position: absolute;
    left: 22px;
    height: 23px;
    width: 10px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream4 {
    position: absolute;
    left: 32px;
    height: 25px;
    width: 12px;
    border-radius: 9px;
    background: #744c28;
    top: 13px;
}
.cake_top1_cream5 {
    position: absolute;
    left: 44px;
    height: 30px;
    width: 13px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream6 {
    position: absolute;
    left: 57px;
    height: 25px;
    width: 13px;
    border-radius: 9px;
    background: #744c28;
    top: 9px;
}
.cake_top1_cream7 {
    position: absolute;
    right: -3px;
    height: 22px;
    width: 8px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream8 {
    position: absolute;
    right: 5px;
    height: 25px;
    width: 10px;
    border-radius: 9px;
    background: #5b3f29;
    top: 8px;
}
.cake_top1_cream9 {
    position: absolute;
    right: 15px;
    height: 31px;
    width: 10px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream10 {
    position: absolute;
    right: 25px;
    height: 15px;
    width: 10px;
    border-radius: 9px;
    background: #5b3f29;
    top: 13px;
}
.cake_top1_cream11 {
    position: absolute;
    right: 35px;
    height: 24px;
    width: 13px;
    border-radius: 9px;
    background: #f14380;
}
.cake_top1_cream12 {
    position: absolute;
    right: 48px;
    height: 15px;
    width: 12px;
    border-radius: 9px;
    background: #5b3f29;
    top: 10px;
}
.cake_top1_cream13 {
    position: absolute;
    right: 60px;
    height: 26px;
    width: 10px;
    border-radius: 9px;
    background: #f14380;
}
.cake_bottom3_creams {
    height: 22px;
    border-radius: 5px;
    background: #ff7fbf;
    position: absolute;
    top: -5px;
    width: 100%;
}
.cake_bottom3_cream1 {
    position: absolute;
    left: -3px;
    height: 35px;
    width: 18px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream2 {
    position: absolute;
    left: 15px;
    height: 24px;
    width: 15px;
    border-radius: 9px;
    top: 12px;
    background: #744c28;
}
.cake_bottom3_cream3 {
    position: absolute;
    left: 30px;
    height: 38px;
    width: 10px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream4 {
    position: absolute;
    left: 40px;
    height: 24px;
    width: 15px;
    border-radius: 9px;
    top: 15px;
    background: #744c28;
}
.cake_bottom3_cream5 {
    position: absolute;
    left: 55px;
    height: 30px;
    width: 15px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream6 {
    position: absolute;
    left: 70px;
    height: 20px;
    width: 12px;
    border-radius: 100px;
    top: 17px;
    background: #744c28;
}
.cake_bottom3_cream7 {
    position: absolute;
    left: 82px;
    height: 40px;
    width: 18px;
    border-radius: 100px;
    background: #ff7fbf;
}
.cake_bottom3_cream8 {
    position: absolute;
    left: 100px;
    height: 20px;
    width: 22px;
    border-radius: 100px;
    top: 10px;
    background: #744c28;
}
.cake_bottom3_cream9 {
    position: absolute;
    left: 122px;
    height: 67px;
    width: 24px;
    border-radius: 100px;
    background: #ff7fbf;
    z-index: 3;
}
.cake_bottom3_cream10 {
    position: absolute;
    left: 146px;
    height: 20px;
    width: 9px;
    border-radius: 100px;
    top: 16px;
    background: #744c28;
}
.cake_bottom3_cream11 {
    position: absolute;
    right: 140px;
    height: 37px;
    width: 15px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream12 {
    position: absolute;
    right: 128px;
    height: 23px;
    width: 12px;
    border-radius: 100px;
    top: 13px;
    background: #5b3f27;
}
.cake_bottom3_cream13 {
    position: absolute;
    left: 116px;
    height: 59px;
    width: 36px;
    border-radius: 100px;
    background: #744c28;
    z-index: 2;
    top: 15px;
}
.cake_bottom3_cream14 {
    position: absolute;
    right: 108px;
    height: 64px;
    width: 20px;
    border-radius: 9px;
    background: #ff7fbf;
    z-index: 3;
}
.cake_bottom3_cream15 {
    position: absolute;
    right: 102px;
    height: 55px;
    width: 31px;
    border-radius: 100px;
    background: #5a4027;
    z-index: 2;
    top: 15px;
}
.cake_bottom3_cream16 {
    position: absolute;
    right: 93px;
    height: 23px;
    width: 15px;
    border-radius: 100px;
    top: 8px;
    background: #5b3f27;
}
.cake_bottom3_cream17 {
    position: absolute;
    right: 78px;
    height: 47px;
    width: 15px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream18 {
    position: absolute;
    right: 70px;
    height: 23px;
    width: 8px;
    border-radius: 100px;
    top: 16px;
    background: #5b3f27;
}
.cake_bottom3_cream19 {
    position: absolute;
    right: 58px;
    height: 30px;
    width: 12px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream20 {
    position: absolute;
    right: 43px;
    height: 23px;
    width: 15px;
    border-radius: 100px;
    top: 8px;
    background: #5b3f27;
}
.cake_bottom3_cream21 {
    position: absolute;
    right: 28px;
    height: 38px;
    width: 15px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom3_cream22 {
    position: absolute;
    right: 10px;
    height: 23px;
    width: 18px;
    border-radius: 100px;
    top: 14px;
    background: #5b3f27;
}
.cake_bottom3_cream23 {
    position: absolute;
    right: -3px;
    height: 30px;
    width: 13px;
    border-radius: 9px;
    background: #ff7fbf;
}
.cake_bottom1_creams {
    height: 10px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.cake_bottom1_cream1 {
    position: absolute;
    left: 56px;
    height: 6px;
    width: 14px;
    border-radius: 20px 20px 0px 0px;
    background: #5a4027;
    bottom: 0px;
}
.cake_bottom1_cream2 {
    position: absolute;
    left: 115px;
    height: 5px;
    width: 10px;
    border-radius: 20px 20px 0px 0px;
    background: #5a4027;
    bottom: 0px;
}
.cake_bottom1_cream3 {
    position: absolute;
    right: 33px;
    height: 8px;
    width: 14px;
    border-radius: 20px 20px 0px 0px;
    background: #744c29;
    bottom: 0px;
}
.cake_bottom1_cream4 {
    position: absolute;
    right: 58px;
    height: 5px;
    width: 11px;
    border-radius: 20px 20px 0px 0px;
    background: #744c29;
    bottom: 0px;
}
.gift_top {
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    o-transition: all 0.6s ease-in-out;
    width: 100%;
    position: absolute;
    top: 0px;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}
.gift:hover .gift_top {
    top: -100px;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}
.gift_ribbon_left {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    o-transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}
.gift_ribbon_center {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    o-transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}
.gift:hover .gift_ribbon_left {
    height: 32px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.gift:hover .gift_ribbon_center {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    height: 102px;
}
.gift_box_bottom_top {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}
.gift:hover .gift_box_bottom_top {
    height: 0px;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    o-transition: all 0.3s ease-in-out;
}
.giftcard {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 1px 0px 1px #fee4c0;
    padding: 10px;
    width: 80px;
    font-size: 15px;
font-family: 'Source Sans Pro', sans-serif;
    color: #ff3f48;
    left: 0px;
    top: 100px;
    right: 0px;
    margin: 0px auto;
    position: absolute;
    line-height: 26px;
    z-index: -1;
    text-align: center;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    o-transition: all 0.6s ease-in-out;
}
.gift:hover .giftcard {
    top: -10px;
}
.one_number {
    position: absolute;
    left: 147px;
    top: 25px;
    width: 9px;
}
.one_number:after {
    content: "";
    height: 5px;
    width: 5px;
    position: absolute;
    background: #c6c6c6;
    top: 0px;
    left: 0px;
}
.one_number:before {
    content: "";
    height: 25px;
    width: 5px;
    position: absolute;
    background: #c6c6c6;
    top: 0px;
    right: 0px;
}
.seven_number {
    position: absolute;
    right: 147px;
    top: 25px;
    width: 14px;
}
.seven_number:after {
    content: "";
    height: 5px;
    width: 20px;
    position: absolute;
    background: #c6c6c6;
    top: 0px;
    left: -2px;
}
.seven_number:before {
    content: "";
    height: 27px;
    width: 5px;
    position: absolute;
    background: #c6c6c6;
    top: 0px;
    right: 0px;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
}
.seven_flame {
    height: 12px;
    width: 6px;
    border-radius: 100%;
    background: #ffd215;
    position: absolute;
    right: 3px;
    -webkit-animation: flame 0.5s infinite linear;
    animation: flame 0.5s infinite linear;
    -moz-animation: flame 0.5s infinite linear;
    bottom: 2px;
}
.one_flame {
    height: 12px;
    width: 6px;
    border-radius: 100%;
    background: #ffd215;
    position: absolute;
    right: 1px;
    bottom: 2px;
    -webkit-animation: flame 0.5s infinite linear;
    animation: flame 0.5s infinite linear;
    -moz-animation: flame 0.5s infinite linear;
}
@-webkit-keyframes flame {
    0% {
        height: 12px;
        background: #fdd214;
    }
    50% {
        height: 14px;
        background: #ffc617;
    }
    100% {
        height: 12px;
        background: #fdd214;
    }
}
@keyframes flame {
    0% {
        height: 12px;
        background: #fdd214;
    }
    50% {
        height: 14px;
        background: #ffc617;
    }
    100% {
        height: 12px;
        background: #fdd214;
    }
}
@-moz-keyframes flame {
    0% {
        height: 12px;
        background: #fdd214;
    }
    50% {
        height: 14px;
        background: #ffc617;
    }
    100% {
        height: 12px;
        background: #fdd214;
    }
}
